import { WebGebruikerForm } from '@/lib/components/forms/WebGebruikerForm';
import { useGetBevoegdGezagen, useGetMilieudiensten } from '@/lib/queries';
import { useGetDeelnemers } from '@/lib/queries/config-api/deelnemer';
import { useGetDeskundigen } from '@/lib/queries/config-api/deskundige';
import { useCreateWebgebruiker } from '@/lib/queries/config-api/webGebruiker';
import { Button, Card, Spin } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const WebGebruikerCreate: React.FunctionComponent = () => {
  const createWebgebruiker = useCreateWebgebruiker();
  const navigate = useNavigate();

  const { data: milieudienstenResponse, isLoading: isMilieudienstenLoading } = useGetMilieudiensten();
  const { data: bevoegdGezagResponse, isLoading: isBevoegdGezagLoading } = useGetBevoegdGezagen({ pageSize: 1000 });
  const { data: deelnemerResponse, isLoading: isDeelnemerLoading } = useGetDeelnemers();
  const { data: deskundigenResponse, isLoading: isDeskundigenLoading } = useGetDeskundigen();

  const formId = 'CreateWebGebruikerForm';
  return (
    <>
      {isMilieudienstenLoading || isBevoegdGezagLoading || isDeelnemerLoading || isDeskundigenLoading ? (
        <Spin size="large" />
      ) : (
        <Card
          title="Web gebruiker aanmaken"
          bordered={true}
          extra={
            <Button type="primary" htmlType="submit" form={formId}>
              Opslaan
            </Button>
          }>
          <WebGebruikerForm
            id={formId}
            onFinish={values => {
              createWebgebruiker.mutate(values, {
                onSuccess: () => {
                  navigate('/webgebruiker');
                },
              });
            }}
            milieudiensten={milieudienstenResponse ?? []}
            deskundigen={deskundigenResponse ?? []}
            bevoegdGezagen={bevoegdGezagResponse?.data ?? []}
            deelnemers={deelnemerResponse?.data ?? []}
            isCreate={true}
          />
        </Card>
      )}
    </>
  );
};
