import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, TableColumnType } from 'antd';

export const getColumnSearchProps = <T,>(
  dataIndex: keyof T,
  handleSearch: (confirm: () => void) => void,
  handleReset: (clearFilters: (() => void) | undefined, confirm: () => void) => void
): TableColumnType<T> => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
      <Input
        placeholder={`Zoeken in ${String(dataIndex)}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onKeyDown={e => e.stopPropagation()}
        onPressEnter={() => handleSearch(confirm)}
        style={{ marginBottom: 8, display: 'block' }}
        autoFocus
      />
      <Space>
        <Button type="primary" onClick={() => handleSearch(confirm)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
          Zoeken
        </Button>
        <Button onClick={() => clearFilters && handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
  onFilter: () => true,
});
