import { MainHeader, RequireLogin } from '@/lib';
import { SideNav } from '@/lib/components/core/sideNav';
import { InteractionStatus } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Layout, Skeleton } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Suspense } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

export const BaseLayout = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const account = useAccount(accounts[0]);

  const onLogin = () => {
    instance.loginRedirect();
  };

  const onLogout = () => {
    instance.logoutRedirect({
      account: account,
      postLogoutRedirectUri: '/',
    });
  };

  return (
    <Layout className={styles.main}>
      <Skeleton loading={inProgress === InteractionStatus.HandleRedirect}>
        <RequireLogin>
          <SideNav selectedKey={location.pathname} onSelect={navigate} />
          <Layout>
            <MainHeader onLogin={onLogin} onLogout={onLogout} isAuthenticated={isAuthenticated} />
            <Content className={styles.content}>
              <Suspense fallback={<Skeleton active loading />}>
                <Outlet />
              </Suspense>
            </Content>
          </Layout>
        </RequireLogin>
      </Skeleton>
    </Layout>
  );
};
