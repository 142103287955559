import { Card } from "antd"

export const PrivacyStatement: React.FunctionComponent = () => {
  return <Card>
    <div style={{ maxWidth: "1000px" }}>
      <h1>Privacy Statement</h1>
      <p>
        Stichting Uitvoeringsorganisatie Glastuinbouw (hierna te noemen: UO) hecht veel waarde aan het
        beschermen van uw privacy en (persoons)gegevens worden door ons dan ook zorgvuldig verwerkt en
        beveiligd. In deze privacyverklaring leggen wij uit welke persoonsgegevens wij verzamelen en
        gebruiken en met welk doel.
      </p>
      <h2>Verwerken van persoonsgegevens</h2>
      <p>
        UO kan uw persoonsgegevens verwerken omdat wij in opdracht van gemeenten en waterschappen
        (het bevoegd gezag) zorgdragen voor de verwerking van milieurapportages van bedrijven. De
        milieurapportages zullen in voorkomend geval persoonsgegevens, uniek UO nummer, NAW-gegevens
        (contactpersoon), telefoonnummer (contactpersoon), e-mailadres, adresgegevens van inrichting,
        handtekening). Via onze website kunt u inloggen (middels E-herkenning) om de milieurapportages in
        te dienen en te bekijken. Deze verwerkingen van persoonsgegevens doen wij op basis van een
        verwerkersovereenkomst met het bevoegd gezag.
        <br /><br />
        Ook kunt u ons uw contactgegevens verstrekken als u met ons wilt communiceren of als u nadere
        informatie/geanonimiseerde analyse(s) wenst te krijgen, of indien u zaken met ons wilt doen. Wij
        verwerken deze persoonsgegevens vervolgens ter uitvoering van onze dienstverlening en/of om u te
        informeren. Zonder deze persoonsgegevens is het namelijk voor ons niet mogelijk uitvoering te
        geven aan onze dienstverlening en/of u te informeren.
        <br /><br />
        Indien u zaken met ons wil doen of wij met u (uw bedrijf of het bedrijf waar u voor werkt) zullen wij
        tot een overeenkomst komen. In dat geval hebben wij verschillende gegevens van u nodig,
        waaronder ook persoonsgegevens. U kunt dan denken aan de volgende persoonsgegevens:
        (zakelijke) contactgegevens (naam, telefoonnummer en e-mailadres), eventueel uw
        bankrekeningnummer.
        <br /><br />
        UO kan op haar website gebruik maken van functionele cookies. Een cookie is een eenvoudig klein
        bestandje dat met pagina’s van deze website wordt meegestuurd en door uw browser op de harde
        schijf van uw computer wordt opgeslagen. Wij gebruiken cookies om uw instellingen en voorkeuren
        te onthouden en om u de mogelijkheid te bieden de formulieren op efficiënte wijze in te vullen. UO
        maakt geen gebruik van commerciële of tracking cookies. U kunt de cookies uitzetten via uw
        browser. De wijze waarop verschilt per browser, raadpleeg hiervoor eventueel de help functie van
        uw browser. U kunt per browser ook instellen dat u volledig afziet van het ontvangen van cookies, dit
        geeft (mogelijk) echter wel een beperking in het gebruik van de website.
      </p>
      <h2>Gebruik van persoonsgegevens</h2>
      <p>
        UO verzamelt en gebruikt uw persoonsgegevens om de opdracht van het bevoegde gezag uit te
        voeren, dan wel door u gevraagde services en diensten te leveren en de website te beheren. UO kan
        uw persoonsgegevens ook gebruiken om u te informeren. Uw persoonsgegevens kunnen wij ook
        gebruiken voor het doen van onderzoek en het uitvoeren van analyses.
        UO heeft een wettelijke grondslag nodig om uw persoonsgegevens te mogen verwerken. De
        persoonsgegevens die wij in opdracht van het bevoegd gezag verwerken, verwerken wij als
        verwerker in de zin van de AVG. Dit doen wij op basis van een verwerkersovereenkomst. Het
        bevoegd gezag is verantwoordelijke.
        <br /><br />
        UO zal de overige persoonsgegevens verwerken als verantwoordelijke welke gebaseerd op
        verwerkingsgronden zoals het uitvoeren van een overeenkomst, op basis van een wettelijke plicht,
        op basis van een gerechtvaardigd belang of middels uw toestemming.
        In het geval van een gerechtvaardigd belang van UO zullen wij uw belangen of grondrechten en
        fundamentele vrijheden niet bovenmatig schaden. U heeft in dat geval te allen tijde het recht om
        vanwege met uw specifieke situatie verband houdende redenen bezwaar te maken tegen de
        verwerking van uw persoonsgegevens.
        <br /><br />
        In het geval van toestemming heeft u te allen tijde het recht deze weer in te trekken. Veelal zal
        echter sprake zijn van verwerking welke (afgeleid) voortvloeit uit de wet.
        Uw privacy wordt gerespecteerd door ons. De informatie en persoonsgegevens worden vertrouwelijk
        behandeld, ook door derden die verantwoordelijk zijn voor de veiligheid van uw gegevensopslag, en
        worden niet voor andere doeleinden gebruikt dan vermeld.
      </p>
      <h2>Doorgifte aan derden</h2>
      <p>
        UO verkoopt, noch verhuurt noch leaset uw persoonsgegevens aan derden. UO zal uw
        persoonsgegevens nooit aan derden openbaren, zonder mededeling, behalve wanneer dit wettelijk
        vereist is, past binnen de dienstverlening van UO aan het bevoegd gezag of wij in goed vertrouwen
        geloven dat een dergelijke handeling nodig is om:
        <ul>
          <li>te voldoen aan een juridisch verzoek; of</li>
          <li>om de rechten of eigendom van UO te beschermen en verdedigen; of</li>
          <li>te handelen onder urgente omstandigheden om de persoonlijke veiligheid van gebruikers van UO of het publiek te beschermen.</li>
        </ul>
        <br /><br />
        Wel kunnen de door u verstrekte persoonsgegevens in sommige gevallen verwerkt worden door
        derden. UO kan bijvoorbeeld een leverancier inhuren om administratieve en operationele
        werkzaamheden uit te voeren ter ondersteuning van onze relatie met u. Deze leveranciers zijn
        gehouden aan contractuele en overige wettelijke verplichtingen om vertrouwelijk met uw
        persoonsgegevens om te gaan en uw privacy te respecteren. Zij hebben dan ook alleen toegang tot
        de persoonsgegevens die ze nodig hebben om hun werkzaamheden uit te voeren. Indien verplicht
        zullen wij met deze derde een verwerkersovereenkomst aangaan.
      </p>
      <h2>Bewaren van persoonsgegevens</h2>
      <p>
        Uitgangspunt voor het bewaren van persoonsgegevens is, dat alle gegevens niet langer worden
        bewaard dan noodzakelijk is. UO zal persoonsgegevens dan ook niet langer bewaren dan strikt nodig
        is om de doelen te realiseren waarvoor de gegevens worden verzameld. Uitgezonderd hiervan zijn de
        gegevens die wij langer moeten bewaren omdat de wet ons dit verplicht.
      </p>
      <h2>Veiligheid van persoonsgegevens</h2>
      <p>
        UO zorgt voor een passende organisatorische, technische en fysieke beveiliging van uw
        persoonsgegevens. UO bewaart de persoonsgegevens die u aanlevert aan computerservers veilig in
        een gecontroleerde, zekere omgeving, beschermd tegen ongemachtigde toegang, gebruik of
        openbaring.
      </p>
      <h2>Geautomatiseerde besluitvorming </h2>
      <p>
        UO neemt niet op basis van geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke)
        gevolgen kunnen hebben voor personen.
      </p>
      <h2>Uw rechten </h2>
      <p>
        U heeft onder andere het recht te verzoeken om informatie, inzage of rectificatie, verwijdering,
        beperking van de verwerking of gevolggeving aan het recht op dataportabiliteit (het recht om te
        vragen om de gegevens die wij over u verwerken over te dragen aan u of aan een andere
        organisatie).
        <br /><br />
        Als u gebruik wilt maken van uw rechten, kunt u een gespecificeerd verzoek naar <a href="mailto:avg@uoglastuinbouw.nl">avg@uoglastuinbouw.nl</a> sturen. Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, kunnen
        wij u vragen een kopie van uw identiteitsbewijs toe te sturen. Maak in dat geval in deze kopie de
        pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort),
        paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. UO zal
        zo snel mogelijk, maar in ieder geval binnen vier weken, op uw verzoek reageren.
        <br /><br />
        Wij zullen altijd ons uiterste best doen om uw verzoeken in te willigen. Toch is dit niet altijd mogelijk,
        bijvoorbeeld als wij wettelijk verplicht zijn om bepaalde gegevens te bewaren.
        <br /><br />
        Tot slot heeft u altijd het recht om een klacht in te dienen bij de Autoriteit Persoonsgegevens. Zij is
        bereikbaar via www.autoriteitpersoonsgegevens.nl .
      </p>
      <h2>Wijzigen van privacy statement</h2>
      <p>
        Dit privacy statement kan worden gewijzigd. UO zal zo nu en dan de privacyverklaring updaten om
        bedrijfs- en klantenfeedback te reflecteren. UO raadt u aan om periodiek deze verklaring te
        raadplegen om op de hoogte te blijven van hoe UO uw informatie beschermt.
      </p>
      <h2>Contact</h2>
      <p>
        UO ontvangt graag uw commentaar betreffende deze privacyverklaring. Als u van mening bent dat
        UO niet heeft gehandeld conform deze verklaring, dan kunt u mailen naar: <a href="mailto:avg@uoglastuinbouw.nl">avg@uoglastuinbouw.nl</a>.
        Stichting Uitvoeringsorganisatie Glastuinbouw Postbus 100 2670 AC Naaldwijk
      </p>
    </div>
  </Card >
}
