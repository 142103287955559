import { Typography } from 'antd';
import React, { CSSProperties } from 'react';
import styles from './accent.module.scss';
import { AccentColor } from './types';

interface AccentProps extends React.PropsWithChildren {
  color: AccentColor;
  style?: CSSProperties;
  className?: string[];
}

export const Accent: React.FunctionComponent<AccentProps> = ({ children, color, style, className }) => {
  const classes = [styles[color], ...(className || [])].join(' ');

  return (
    <Typography.Text style={style} className={classes}>
      {children}
    </Typography.Text>
  );
};
