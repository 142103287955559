import { useGetDeelnemers } from '@/lib/queries/config-api/deelnemer';
import { BasicTableColumn } from '@/lib/types';
import { DeelnemerOutput } from '@/lib/types/deelnemer';
import { BasicTable } from '@/lib/components/tables/BasicTable';
import { Button, Card, Table, TablePaginationConfig, TableProps } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const DeelnemerOverview: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const columns: BasicTableColumn<DeelnemerOutput>[] = [
    { label: 'deelnemer.bedrijfsnaam', dataIndex: 'bedrijfsnaam', sortable: true, filterable: true },
    { label: 'deelnemer.contactPersoon', dataIndex: 'contactPersoon', sortable: true, filterable: true },
    { label: 'deelnemer.actief', dataIndex: 'actief', render: (value) => (value ? 'Ja' : 'Nee'), },
    { label: 'deelnemer.vestigingsStraat', dataIndex: 'vestigingsStraat', sortable: true, filterable: true },
    { label: 'deelnemer.vestigingsNummer', dataIndex: 'vestigingsNummer', sortable: true, filterable: true },
    { label: 'deelnemer.vestigingsNummerToevoeging', dataIndex: 'vestigingsNummerToevoeging', sortable: true, filterable: true, width: 2 },
    { label: 'deelnemer.vestigingsPostcode', dataIndex: 'vestigingsPostcode', sortable: true, filterable: true },
    { label: 'deelnemer.vestigingsPlaats', dataIndex: 'vestigingsPlaats', sortable: true, filterable: true },
  ];

  return (
    <>
      <Card
        title="Deelnemers overzicht"
        bordered={true}
        extra={
          <Button disabled type="primary" onClick={() => navigate('create')}>
            Nieuwe aanmaken
          </Button>
        }>
        <BasicTable<DeelnemerOutput> loadEntries={useGetDeelnemers} columns={columns} />
      </Card>
    </>
  );
};
