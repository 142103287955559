import { createFilterParams, fetchAuthenticated } from '@/lib/utilities/api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { DeskundigeOutput } from '@/lib/types/deskundige';
import { PaginationQueryParameters } from '@/shared/types/general';

const QUERY_KEY = ['deskundige'];
const BASE_URL = `${import.meta.env.VITE_CONFIG_API_URL}/deskundige`;

export const useGetDeskundigen = (params?: PaginationQueryParameters<DeskundigeOutput>): UseQueryResult<DeskundigeOutput[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => fetchAuthenticated<DeskundigeOutput[]>(`${BASE_URL}?${createFilterParams(params ?? {})}`),
  });
};
