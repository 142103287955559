import './form.scss';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';

export interface MilieudienstFormProps {
  onFinish: (values: any) => void | undefined;
  initialValues?: Store | undefined;
  id?: string | undefined;
}
export const MilieudienstForm: React.FunctionComponent<MilieudienstFormProps> = ({ onFinish, initialValues, id }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('core');

  const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 14 } };

  return (
    <Form id={id} className="form twoColumn" {...formItemLayout} layout={'horizontal'} form={form} initialValues={initialValues ?? {}} onFinish={onFinish}>
      <Form.Item label={t('milieudienst.naam')} name="naam" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('milieudienst.contactEmail')} name="contactEmail" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('milieudienst.contactPersoon')} name="contactPersoon" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('milieudienst.telefoon')} name="telefoon" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
    </Form>
  );
};
