import './form.scss';
import { Form, Input, InputNumber, Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { BevoegdGezagTypeOutput, Provincie } from '@/lib/types';
import { Store } from 'antd/es/form/interface';
import { MilieudienstOutput } from '@/lib/types/milieudienst';

export interface BevoegdGezagFormProps {
  onFinish: (values: any) => void | undefined;
  milieudiensten: MilieudienstOutput[];
  provincies: Provincie[];
  bevoegdGezagTypes: BevoegdGezagTypeOutput[];
  initialValues?: Store | undefined;
  id?: string | undefined;
}
export const BevoegdGezagForm: React.FunctionComponent<BevoegdGezagFormProps> = ({
  onFinish,
  milieudiensten,
  provincies,
  bevoegdGezagTypes,
  initialValues,
  id,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('core');

  const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 14 } };

  return (
    <Form
      id={id}
      className="form twoColumn"
      {...formItemLayout}
      layout={'horizontal'}
      form={form}
      initialValues={initialValues ?? { actief: true }}
      onFinish={onFinish}>
      <Form.Item label={t('bevoegdGezag.fullName')} name="volledigeNaam" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.street')} name="straat" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.number')} name="nummer" rules={[{ required: false }]}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.numberAdditive')} name="nummerToevoeging" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.postcode')} name="postcode" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.city')} name="plaats" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.active')} valuePropName="checked" name="actief" rules={[{ required: true }]}>
        <Switch defaultChecked={true} />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.province')} name="provincieId" rules={[{ required: false }]}>
        <Select
          allowClear
          options={provincies?.map((item, _) => {
            return {
              value: item.id,
              label: item.naam,
            };
          })}
        />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.type')} name="bevoegdGezagTypeId" rules={[{ required: true }]}>
        <Select
          allowClear
          options={bevoegdGezagTypes?.map((item, _) => {
            return { value: item.id, label: item.naam };
          })}
        />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.environmentalService')} name="milieudienstId" rules={[{ required: false }]}>
        <Select
          allowClear
          options={milieudiensten?.map((item, _) => {
            return { value: item.id, label: item.volledigeNaam };
          })}
        />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.email')} name="email" rules={[{ required: true, type: 'email' }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.upperExcentricityN')} name="bovengrensExcentriciteitN" rules={[{ required: false }]}>
        <InputNumber step={0.1} />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.lowerExcentricityN')} name="ondergrensExcentriciteitN" rules={[{ required: false }]}>
        <InputNumber step={0.1} />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.upperExcentricityP')} name="bovengrensExcentriciteitP" rules={[{ required: false }]}>
        <InputNumber step={0.1} />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.lowerExcentricityP')} name="ondergrensExcentriciteitP" rules={[{ required: false }]}>
        <InputNumber step={0.1} />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.contactPerson')} name="contactPersoon" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.telephone')} name="telefoon" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('bevoegdGezag.contactEmail')} name="contactEmail" rules={[{ required: false, type: 'email' }]}>
        <Input />
      </Form.Item>
    </Form>
  );
};
