import { Card } from 'antd';
import React from 'react';
import styles from './styles.module.scss';
import logo from "@/assets/logo.jpg";

export const Home: React.FunctionComponent = () => {
  return <Card className={styles.home} >
    <img src={logo} />
    <h2>BackOffice</h2>
  </Card>;
};