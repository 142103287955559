import { useGetBevoegdGezagen } from '@/lib/queries';
import { BasicTableColumn } from '@/lib/types';
import { BevoegdGezagOutput } from '@/lib/types/bevoegdgezag';
import { BasicTable } from '@/lib/components/tables/BasicTable';
import { Button, Card } from 'antd';
import { useNavigate } from 'react-router-dom';

export const BevoegdGezagOverview: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const columns: BasicTableColumn<BevoegdGezagOutput>[] = [
    { label: 'bevoegdGezag.fullName', dataIndex: 'volledigeNaam', filterable: true, sortable: true, width: 150 },
    { label: 'bevoegdGezag.street', dataIndex: 'straat', sortable: true, filterable: true, width: 150 },
    { label: 'bevoegdGezag.number', dataIndex: 'nummer', sortable: true, filterable: true, width: 150 },
    { label: 'bevoegdGezag.numberAdditive', dataIndex: 'nummerToevoeging', sortable: true, filterable: true, width: 150 },
    { label: 'bevoegdGezag.postcode', dataIndex: 'postcode', filterable: true, sortable: true, width: 150 },
    { label: 'bevoegdGezag.city', dataIndex: 'plaats', filterable: true, sortable: true, width: 150 },
    { label: 'bevoegdGezag.active', dataIndex: 'actief', render: (value: unknown) => (value ? 'Ja' : 'Nee'), width: 150 },
    { label: 'bevoegdGezag.bevoegdGezagType', dataIndex: 'bevoegdGezagType', width: 150 },
    { label: 'bevoegdGezag.province', dataIndex: 'provincie', width: 150 },
    { label: 'bevoegdGezag.email', dataIndex: 'email', filterable: true, sortable: true, width: 150 },
    { label: 'bevoegdGezag.upperExcentricityN', dataIndex: 'bovengrensExcentriciteitN', sortable: true, width: 150 },
    { label: 'bevoegdGezag.lowerExcentricityN', dataIndex: 'ondergrensExcentriciteitN', sortable: true, width: 150 },
    { label: 'bevoegdGezag.upperExcentricityP', dataIndex: 'bovengrensExcentriciteitP', sortable: true, width: 150 },
    { label: 'bevoegdGezag.lowerExcentricityP', dataIndex: 'ondergrensExcentriciteitP', sortable: true, width: 150 },
    { label: 'bevoegdGezag.milieudienst', dataIndex: 'milieudienst', width: 150 },
    { label: 'bevoegdGezag.contactPerson', dataIndex: 'contactpersoon', filterable: true, sortable: true, width: 150 },
    { label: 'bevoegdGezag.telephone', dataIndex: 'telefoon', filterable: true, width: 150 },
    { label: 'bevoegdGezag.contactEmail', dataIndex: 'contactEmail', filterable: true, sortable: true, width: 150 },
  ];

  return (
    <Card
      title="Bevoegd gezag overzicht"
      bordered={true}
      extra={
        <Button type="primary" onClick={() => navigate('create')}>
          Nieuwe aanmaken
        </Button>
      }>
      <BasicTable<BevoegdGezagOutput> loadEntries={useGetBevoegdGezagen} columns={columns} onEdit={id => navigate(id.toString())} />
    </Card>
  );
};
