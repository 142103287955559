import { MilieudienstForm } from '@/lib/components/forms/MilieudienstForm';
import { useGetMilieudienstById, useUpdateMilieudienst } from '@/lib/queries';
import { Button, Card, Spin } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const MilieudienstEdit: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const id = Number(useParams().id?.toString());
  const updateMilieudienst = useUpdateMilieudienst(id);
  const { data: milieudienstenResponse, isLoading: isMilieudienstenLoading } = useGetMilieudienstById(id);

  const formId = 'editMilieudienstForm';
  return (
    <>
      {isMilieudienstenLoading ? (
        <Spin size="large" />
      ) : (
        <Card
          title="Milieudienst aanpassen"
          bordered={true}
          extra={
            <Button type="primary" htmlType="submit" form={formId}>
              Opslaan
            </Button>
          }>
          <MilieudienstForm
            id={formId}
            onFinish={values => {
              updateMilieudienst.mutate(
                { ...values },
                {
                  onSuccess: () => {
                    navigate('/milieudienst');
                  },
                }
              );
            }}
            initialValues={{
              naam: milieudienstenResponse?.volledigeNaam,
              contactEmail: milieudienstenResponse?.contactEmail,
              contactPersoon: milieudienstenResponse?.contactPersoon,
              telefoon: milieudienstenResponse?.telefoon,
            }}
          />
        </Card>
      )}
    </>
  );
};

