import { BasicTableColumn } from '@/lib/types';
import { BasicTable } from '@/lib/components/tables/BasicTable';
import { Button, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { MilieudienstOutput } from '@/lib/types/milieudienst';
import { useGetMilieudienstenPaged } from '@/lib/queries';

export const MilieudienstOverview: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const columns: BasicTableColumn<MilieudienstOutput>[] = [
    { label: 'milieudienst.naam', dataIndex: 'volledigeNaam', filterable: true, sortable: true, width: 150 },
    { label: 'milieudienst.contactEmail', dataIndex: 'contactEmail', filterable: true, sortable: true, width: 150 },
    { label: 'milieudienst.contactPersoon', dataIndex: 'contactPersoon', filterable: true, sortable: true, width: 150 },
    { label: 'milieudienst.telefoon', dataIndex: 'telefoon', filterable: true, sortable: true, width: 150 },
  ];

  return (
    <Card title="Milieudiensten overzicht" bordered={true}>
      <BasicTable<MilieudienstOutput> loadEntries={useGetMilieudienstenPaged} columns={columns} onEdit={id => navigate(id.toString())} />
    </Card>
  );
};
