import { FC } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { BevoegdGezagCreate } from './routes/bevoegdgezag/create/page';
import { BevoegdGezagEdit } from './routes/bevoegdgezag/edit/page';
import { BevoegdGezagOverview } from './routes/bevoegdgezag/page';
import { BaseLayout } from './routes/layout';
import { Home } from './routes/page';
import { WebGebruikerOverview } from './routes/webgebruiker/page';
import { WebGebruikerCreate } from './routes/webgebruiker/create/page';
import { WebGebruikerEdit } from './routes/webgebruiker/edit/page';
import { DeelnemerOverview } from './routes/deelnemer/page';
import { PrivacyStatement } from './routes/privacy/page';
import { MilieudienstOverview } from './routes/milieudienst/page';
import { MilieudienstEdit } from './routes/milieudienst/edit/page';

export const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

const Routes = () => {
  const routes = useRoutes([
    {
      path: '',
      element: <BaseLayout />,
      children: [
        { element: <Home />, path: '/' },
        {
          path: '/privacy',
          children: [{ element: <PrivacyStatement />, path: '' }],
        },
        {
          path: '/bevoegdgezag',
          children: [
            { element: <BevoegdGezagOverview />, path: '' },
            { element: <BevoegdGezagCreate />, path: 'create' },
            { element: <BevoegdGezagEdit />, path: ':id' },
          ],
        },
        {
          path: '/webgebruiker',
          children: [
            { element: <WebGebruikerOverview />, path: '' },
            { element: <WebGebruikerCreate />, path: 'create' },
            { element: <WebGebruikerEdit />, path: ':id' },
          ],
        },
        {
          path: '/deelnemer',
          children: [{ element: <DeelnemerOverview />, path: '' }],
        },
        {
          path: '/milieudienst',
          children: [
            { element: <MilieudienstOverview />, path: '' },
            { element: <MilieudienstEdit />, path: ':id' }
          ],
        },
      ],
    },
  ]);
  return routes;
};
