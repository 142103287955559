import { BevoegdGezagForm } from '@/lib/components/forms/BevoegdGezagForm';
import { useGetBevoegdGezagById, useGetBevoegdGezagTypes, useGetMilieudiensten, useGetProvincies, useUpdateBevoegdGezag } from '@/lib/queries';
import { Button, Card, Spin } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const BevoegdGezagEdit: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const id = Number(useParams().id?.toString());
  const updateBevoegdGezag = useUpdateBevoegdGezag(id);
  const { data: bevoegdGezagResponse, isLoading: isBevoegdGezagLoading } = useGetBevoegdGezagById(id);
  const { data: milieudienstenResponse, isLoading: isMilieudienstenLoading } = useGetMilieudiensten();
  const { data: bevoegdGezagTypeResponse, isLoading: isBevoegdGezagTypeLoading } = useGetBevoegdGezagTypes();
  const { data: provincieResponse, isLoading: isProvinciesLoading } = useGetProvincies();

  const formId = "editBevoegdGezagForm"
  return (
    <>
      {isBevoegdGezagLoading || isMilieudienstenLoading || isBevoegdGezagTypeLoading || isProvinciesLoading ? (
        <Spin size="large" />
      ) : (
        <Card
          title="Bevoegd gezag aanpassen"
          bordered={true}
          extra={
            <Button type="primary" htmlType="submit" form={formId}>
              Opslaan
            </Button>
          }>
          <BevoegdGezagForm
            id={formId}
            onFinish={values => {
              updateBevoegdGezag.mutate({ ...values, nummer: values.nummer.toString(), telefoon: !!values.telefoon ? values.telefoon : undefined }, {
                onSuccess: () => {
                  navigate('/bevoegdgezag');
                },
              });
            }}
            initialValues={{
              actief: bevoegdGezagResponse?.actief,
              volledigeNaam: bevoegdGezagResponse?.volledigeNaam,
              straat: bevoegdGezagResponse?.straat,
              nummer: bevoegdGezagResponse?.nummer,
              nummerToevoeging: bevoegdGezagResponse?.nummerToevoeging,
              postcode: bevoegdGezagResponse?.postcode,
              plaats: bevoegdGezagResponse?.plaats,
              bevoegdGezagTypeId: bevoegdGezagResponse?.bevoegdGezagTypeId,
              provincieId: bevoegdGezagResponse?.provincieId,
              milieudienstId: bevoegdGezagResponse?.milieudienstId,
              email: bevoegdGezagResponse?.email,
              bovengrensExcentriciteitN: bevoegdGezagResponse?.bovengrensExcentriciteitN,
              ondergrensExcentriciteitN: bevoegdGezagResponse?.ondergrensExcentriciteitN,
              bovengrensExcentriciteitP: bevoegdGezagResponse?.bovengrensExcentriciteitP,
              ondergrensExcentriciteitP: bevoegdGezagResponse?.ondergrensExcentriciteitP,
              contactPersoon: bevoegdGezagResponse?.contactpersoon,
              telefoon: bevoegdGezagResponse?.telefoon,
              contactEmail: bevoegdGezagResponse?.contactEmail,
            }}
            milieudiensten={milieudienstenResponse ?? []} provincies={provincieResponse ?? []} bevoegdGezagTypes={bevoegdGezagTypeResponse ?? []} />
        </Card>
      )}
    </>
  );
};
