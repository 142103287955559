import { createFilterParams, fetchAuthenticated } from '@/lib/utilities/api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { DeelnemerOutput } from '@/lib/types/deelnemer';
import { PagedResponse, PaginationQueryParameters } from '@/shared/types/general';

const QUERY_KEY = ['deelnemer'];
const BASE_URL = `${import.meta.env.VITE_CONFIG_API_URL}/deelnemers`;

export const useGetDeelnemers = (params?: PaginationQueryParameters<DeelnemerOutput>): UseQueryResult<PagedResponse<DeelnemerOutput>, Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => fetchAuthenticated<PagedResponse<DeelnemerOutput>>(`${BASE_URL}?${createFilterParams(params ?? {})}`),
  });
};
