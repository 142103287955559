import Logo from '@/assets/logo.jpg';
import { Layout, Menu, MenuProps } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './core.module.scss';

type MenuItem = Required<MenuProps>['items'][number];
const { Sider } = Layout;

interface SiderProps {
  selectedKey: string;
  onSelect: (key: string) => void;
}

function getItem(label: string, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: string): MenuItem {
  return {
    label,
    key,
    icon: icon ? icon : null,
    children,
    type,
  } as MenuItem;
}

export const SideNav: React.FunctionComponent<SiderProps> = ({ selectedKey, onSelect }) => {
  const { t } = useTranslation('core');
  const items: MenuProps['items'] = useMemo(
    () => [
      getItem(t('menu.home'), '/'),
      getItem(t('menu.bevoegdGezag'), '/bevoegdgezag'),
      getItem(t('menu.webGebruiker'), '/webgebruiker'),
      getItem(t('menu.deelnemer'), '/deelnemer'),
      getItem(t('menu.milieudienst'), '/milieudienst'),
    ],
    [t]
  );
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sider className={styles.sideNav} collapsed={collapsed} width={250} onCollapse={value => setCollapsed(value)} collapsible breakpoint="lg">
      <div className={styles.logo}>
        <img src={Logo}></img>
      </div>

      <Menu activeKey={selectedKey} defaultSelectedKeys={[selectedKey]} onSelect={e => onSelect(e.key)} mode="inline" items={items} />
    </Sider>
  );
};