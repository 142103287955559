import { createFilterParams, fetchAuthenticated } from '@/lib/utilities/api';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PagedResponse, PaginationQueryParameters } from '@/shared/types/general';
import { MilieudienstInput, MilieudienstOutput } from '@/lib/types/milieudienst';

const QUERY_KEY = ['milieudiensten'];
const BASE_URL = `${import.meta.env.VITE_CONFIG_API_URL}/milieudienst`;

export const useGetMilieudienstenPaged = (params?: PaginationQueryParameters<MilieudienstOutput>): UseQueryResult<PagedResponse<MilieudienstOutput>, Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => fetchAuthenticated<PagedResponse<MilieudienstOutput>>(`${BASE_URL}-paged?${createFilterParams(params ?? {})}`),
  });
};

export const useGetMilieudiensten = (params?: PaginationQueryParameters<MilieudienstOutput>): UseQueryResult<MilieudienstOutput[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => fetchAuthenticated<MilieudienstOutput[]>(`${BASE_URL}?${createFilterParams(params ?? {})}`),
  });
};

export const useGetMilieudienstById = (id?: number): UseQueryResult<MilieudienstOutput, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, id],
    queryFn: () => fetchAuthenticated<MilieudienstOutput>(`${BASE_URL}/${id}`),
    enabled: !!id,
  });

export const useUpdateMilieudienst = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation<MilieudienstInput, Error, MilieudienstInput>({
    mutationFn: (newMilieudienst: MilieudienstInput) =>
      fetchAuthenticated<MilieudienstInput>(`${BASE_URL}/${id}`, {
        json: newMilieudienst,
        method: 'PUT',
      }),
    mutationKey: [...QUERY_KEY, id],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...QUERY_KEY, id] });
    },
  });
};