import { BevoegdGezagForm } from '@/lib/components/forms/BevoegdGezagForm';
import { useCreateBevoegdGezag, useGetBevoegdGezagTypes, useGetMilieudiensten, useGetProvincies } from '@/lib/queries';
import { Button, Card } from 'antd';
import Spin from 'antd/lib/spin';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const BevoegdGezagCreate: React.FunctionComponent = () => {
  const createBevoegdGezag = useCreateBevoegdGezag();
  const navigate = useNavigate();

  const { data: milieudienstenResponse, isLoading: isMilieudienstenLoading } = useGetMilieudiensten();
  const { data: bevoegdGezagTypeResponse, isLoading: isBevoegdGezagTypeLoading } = useGetBevoegdGezagTypes();
  const { data: provincieResponse, isLoading: isProvinciesLoading } = useGetProvincies();

  const formId = "createBevoegdGezagForm"
  return (
    <>
      {isMilieudienstenLoading || isBevoegdGezagTypeLoading || isProvinciesLoading ? (
        <Spin size="large" />
      ) : (
        <Card
          title="Bevoegd gezag aanmaken"
          bordered={true}
          extra={
            <Button type="primary" htmlType="submit" form={formId}>
              Opslaan
            </Button>
          }>
          <BevoegdGezagForm
            id={formId}
            onFinish={values => {
              createBevoegdGezag.mutate({ ...values, nummer: values.nummer.toString(), telefoon: !!values.telefoon ? values.telefoon : undefined }, {
                onSuccess: () => {
                  navigate('/bevoegdgezag');
                },
              });
            }}
            milieudiensten={milieudienstenResponse ?? []} provincies={provincieResponse ?? []} bevoegdGezagTypes={bevoegdGezagTypeResponse ?? []}
          />
        </Card>
      )}
    </>
  );
};