import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import React from 'react';
import { ErrorComponent } from '../components/core/errorComponent';
import { loginRequest } from '../utilities/msal';

export const RequireLogin = ({ children }: React.PropsWithChildren): JSX.Element => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} errorComponent={ErrorComponent} authenticationRequest={loginRequest}>
      {children}
    </MsalAuthenticationTemplate>
  );
};
