import { UserAvatar } from '@/lib/components/core/auth/userAvatar';
import { useUserContext } from '@/lib/context/userContext';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './core.module.scss';
import { useNavigate } from 'react-router-dom';
const { Header } = Layout;

interface MainHeaderProps {
  onLogin: () => void;
  onLogout: () => void;
  isAuthenticated: boolean;
}

export const MainHeader: React.FunctionComponent<MainHeaderProps> = ({ onLogin, onLogout, isAuthenticated }) => {
  const { t } = useTranslation('core');
  const navigate = useNavigate();
  const { username } = useUserContext();

  return (
    <Header className={styles.header}>
      {isAuthenticated ? (
        <UserAvatar
          user={username}
          items={[
            {
              key: 1,
              label: t('userMenu.privacy'),
              icon: <InfoCircleOutlined />,
              onClick: () => navigate("/privacy")
            },
            {
              key: 2,
              label: t('userMenu.logout'),
              icon: <UserOutlined />,
              onClick: onLogout,
            },
          ]}
        />
      ) : (
        <Button type="primary" onClick={onLogin}>
          {t('userMenu.login')}
        </Button>
      )}
    </Header>
  );
};