import { createFilterParams, fetchAuthenticated } from '@/lib/utilities/api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { Provincie } from '../../types/bevoegdgezag';
import { PaginationQueryParameters } from '@/shared/types/general';

const QUERY_KEY = ['provincies'];
const BASE_URL = `${import.meta.env.VITE_CONFIG_API_URL}/provincie`;

export const useGetProvincies = (params?: PaginationQueryParameters<Provincie>): UseQueryResult<Provincie[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => fetchAuthenticated<Provincie[]>(`${BASE_URL}?${createFilterParams(params ?? {})}`),
  });
};
