import { Accent } from '@/lib';
import { useGetWebGebruikers, useRequestAzureAccount } from '@/lib/queries/config-api/webGebruiker';
import { BasicTableColumn, BevoegdGezagOutput } from '@/lib/types';
import { WebGebruikerOutput } from '@/lib/types/webgebruiker';
import { BasicTable } from '@/lib/components/tables/BasicTable';
import { CheckCircleOutlined, CloudOutlined } from '@ant-design/icons';
import { App, Badge, Button, Card, Col, Modal, Row, TablePaginationConfig } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const WebGebruikerOverview: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [selection, setSelection] = useState<WebGebruikerOutput[]>([]);
  const { mutateAsync: requestAzureAccount } = useRequestAzureAccount();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { message } = App.useApp();

  const columns: BasicTableColumn<WebGebruikerOutput>[] = [
    { label: 'webGebruiker.loginNaam', dataIndex: 'loginNaam', sortable: true, filterable: true },
    { label: 'webGebruiker.actief', dataIndex: 'actief', render: (value) => (value ? 'Ja' : 'Nee') },
    { label: 'webGebruiker.email', dataIndex: 'email', filterable: true, sortable: true },
    { label: 'webGebruiker.contactpersoon', dataIndex: 'contactpersoon', filterable: true, sortable: true },
    { label: 'webGebruiker.functieContactpersoon', dataIndex: 'functieContactpersoon', filterable: true, sortable: true },
    { label: 'webGebruiker.ondertekenaar', dataIndex: 'ondertekenaar', filterable: true, sortable: true },
    { label: 'webGebruiker.functieOndertekenaar', dataIndex: 'functieOndertekenaar', filterable: true, sortable: true },
    {
      label: 'webGebruiker.azureSubjectId', dataIndex: 'azureSubjectId', render: (value) =>
        value ? (
          <Accent color="success">
            <CheckCircleOutlined />
          </Accent>
        ) : null
    },
  ]

  const createAzureAccounts = async () => {
    try {
      setIsLoading(true);
      for (const user of selection) {
        if (user.azureSubjectId) {
          // We want to skip these
          continue;
        }
        await requestAzureAccount(user.id);
      }
      message.success('Succesvol aangemaakt');
    } catch (e) {
      message.error('Fout tijdens aanmaken accounts, probeer opnieuw');
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
      setSelection([]);
    }
  };

  return (
    <>
      <Card
        title="Web gebruikers overzicht"
        bordered={true}
        extra={
          <Row gutter={16}>
            <Col>
              <Button type="primary" onClick={() => navigate('create')}>
                Nieuwe aanmaken
              </Button>
            </Col>
            <Col>
              <Badge count={selection.length}>
                <Button type="default" icon={<CloudOutlined />} disabled={!selection.length} loading={isLoading} onClick={() => setIsModalOpen(true)}>
                  Maak inlogaccount
                </Button>
              </Badge>
              <Modal
                open={isModalOpen}
                confirmLoading={isLoading}
                title={'Maak inlogaccount'}
                onOk={() => createAzureAccounts()}
                onCancel={() => setIsModalOpen(false)}></Modal>
            </Col>
          </Row>
        }>
        <BasicTable<WebGebruikerOutput> loadEntries={useGetWebGebruikers} columns={columns} onEdit={(id) => navigate(id.toString())} setSelection={setSelection} selection={selection} />
      </Card>
    </>
  );
};
